import React from 'react';
import styled from 'styled-components';
import { Fade } from "react-awesome-reveal";

interface Props {
    images: { src: string, caption: string }[];
}

function ImageSection({images}: Props) {
    return (
        <Section>
            <Grid>
                {images.map((img, index) => (
                    <Fade key={index}>
                        <ImageContainer>
                            <Image src={img.src} alt={img.caption} />
                            <Caption>{img.caption}</Caption>
                        </ImageContainer>
                    </Fade>
                ))}
            </Grid>
        </Section>
    );
}

const Section = styled.section`
    padding: 30px 80px;
`;

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
`;

const ImageContainer = styled.div`
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease-in-out;

    &:hover {
        transform: scale(1.05);
    }
`;

const Image = styled.img`
    width: 100%;
    height: auto;
    display: block;
    border-radius: 15px;
`;

const Caption = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px 15px;
    font-size: 1.2em;
    font-weight: 300;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
     font-family: "Satisfy", cursive;
`;

export default ImageSection;
