import React from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';
import ScratchOff from './ScratchOff.tsx';

function LetterSection() {

  const text = "A lot has changed since I started this (and not just the format of this letter, sorry if it’s not the best website you’ve ever seen; I wasn’t able to get Billy to design it ya know). We’re going to be Aunt Moe and Uncle Shabby to Jennis’s child. It’s been two years instead of one. Your applications are in, and now we wait for all the interviews and acceptances you’ll be getting. I wish this letter would self-destruct by the time you got to the end. I promised you a paragraph a long time ago, and while this is well overdue, with my spectacular commentary, you got a bit more than that. Maybe this is what you expected from this noggin of mine. Hope it was an exciting read. I think I’ve hit my word quota for the week so I won’t be able to text ya. Sorryyyyyyy… I can't believe the original self-proclaimed “I hate sappy things” girl made me do this. SMH. Guess we’re gonna have to make that St. Louis Arch rom-com story a reality as well after all.";
  
  return (
    <HeaderContainer>
      <Fade>
        <TextSection>
          <Title>Long Awaited...</Title>
          <Text>
            Sometimes I still can't believe how we got to where we are today, with all the unique and hilarious experiences we’ve had along the way. Who would’ve thought that all this would spark from an Instagram dm, that being a video of Brian and I’s glorious machine learning project (which you’re now the expert of). I remember vividly the night I listened to you talk (attentively I may add) about your photo wall for an hour while munching on Ritz crackers at your house party. Little did I know I’d spend countless nights after sleeping facing that very wall.          </Text> 
          <Text>
            You invited me to your Stat final presentation as a joke and I actually went, but thought it would be weird, so I sat in the library outside of your class. And our first Lighting of the Lawn, all I did was talk to you (still sad they ran out of hot chocolate, though). Then there was the night I was the sober brother for our frat party and got to witness your little, let's say, “leave a mark” on our home porch. The memories rush back whenever I take a moment to reminisce.          </Text>
          <Text>
            There was that time I was blacked out with Arjun, hugging the street, and I so badly wanted you to come over. Sadly, I was not coherent enough to make conversation when you did arrive, perhaps that was on you for not making it over quickly enough ya know. The morning after I was so worried I had said something stupid like, “I’m in love with you,” because even that early on I knew I wanted to spend all my time with you, even if it may not have been “love” as I know it today. I still regret not kissing you earlier. Oh that night when you were trying to read the oven clock while I was hugging you from behind was magical. I wish I could go back and kiss you then.          </Text>
          <Text>
            Remember the late nights at the library where we got absolutely nothing done but just talked for hours? I’d walk you home, and you’d always ask if I wanted some water. We’d sit on those kitchen stools forever, playing games like trying to catch each other's tongues, which would inevitably lead to a full-blown make out session because I was too scared to be too forward and just kiss you from the get go. Now I literally can’t keep my lips off yours, I wish I could super glue them on. I can’t resist kissing you; you look so gorgeous every time I see you. And while PDA isn’t my thing, one drink and I’ll do anything you want in the middle of the street for all I care. (Or against a wall in an alley of NYC)          </Text>      
          <Text>
            I was so nervous for our first kiss, but the entire night was amazing (besides me almost getting stood up smh smh). You looked so pretty. I loved talking at the Dairy Market and the beers we got after at Starr Hill. Tbh I really wanted to take you to Vivace even if it meant we had to Uber to impress you but I realized I'd probably come off as crazy (Which I guess I am). If I could go back knowing where we are today, I would melt hearing you talk knowing I won you in the end. The weather was perfect, and the memory of us playing with that light-up balloon warms my heart every time it crosses my mind.          </Text>
          <Text>
            My favorite date to this day will always be Vivace. Everything was perfect, the food, the company, and the feeling of being alone together since none of your roommates were home as everyone had left for break. I loved sitting with you, watching TV and splitting a bottle of wine. I’m sure no TV was actually watched but I’m sure a lot of our nights together as adults will look like that. Lol that was why I bought a TV for my room in NY, to cut to the chase and cuddle you in bed. (What I mean by that is, strap you in a straightjacket, hold your eyes open, and force you to watch Star War for once and for all). My first week of work, I can't tell you how much I wanted to be in your arms the second I got off. I can’t wait to come home to you, lounge on the sofa in our makeshift pillow fort, cuddle, and drink wine all night. Though I might have to carry my sleepy princess to bed at some point so you can get a good night’s rest. The popcorn maker was such a cute gift, and I loved when we used it and got Sour Patch Kids, only to fall asleep during the movie again. I think I ended the Vivace night by saying I was gonna rip your clothes off- yaaa, not much of a charmer, I know.
          </Text>
          <Text>
            I loved all our excursions, from small to big. The wack activities we did together, like visiting that country store, making s’mores, watching the train at Wegmans, picking strawberries, building the gingerbread house, and trips to Carter’s Mountain. The petting zoo, the cake and sparkling wine sampling, and brunch where I got to witness how the love of my life devours chocolate chip pancakes starting at the center. I loved going out with you, whether it was dancing at Boylan or making out in a corner of Crozet. All those jumbo margaritas we destroyed together led to some amazing nights, making my love for you even deeper. All our drunken walks back to the Standard with you by my side. I wish I had taken you to the Lawn one of those nights to watch the stars. Even if you were just getting coffee from Panera or groceries, I wanted to be with you. 
          </Text>
          <Text>
            I enjoyed the Outer Banks and our cute walk on the beach. I wish you were feeling better so you could've had fun but I loved our adorable boardwalk crawl on the Jersey Shore, from the arcade to the Ferris wheel. When you stayed at mine for the Fourth of July, I loved kissing you under the fireworks and getting ice cream at Ralphs with you late at night. Eating dinner on the floor made me think about how when we move in together we’ll do the same, and while it may be scary, we will have each other and therefore everything will be okay. When you texted your sister, “I want to marry him,” I think my heart skipped a beat. No one has ever loved me the way you have. You have always given me so much attention, and you still do, and I appreciate it. I don’t know what I’d do if I weren’t texting you all day.
          </Text>
          <Text>
            I had so much fun at the Mets game, and as a hoarder, I love keeping mementos of our memories like the wine bottles from the Fourth of July, the nachos hat from the game, or even the light from the balloon we played with on our first date. All the money I’ve given Tao to make out with you in the middle of a club, I’d do it all over again, even if it was twice as much. I truly can’t keep my eyes, hands, or lips off you. You are beautiful and you have the softest, smoothest skin ever. I’m very jealous, it’s like holding a cloud and I never want to let go.
          </Text>
          <Text>
            All the nights we stayed up until 5 a.m. my mom used to ask who I was texting because I was always smiling at my phone. You always have made me laugh, and you still do. I still remember the trivia guy in Europe getting mad at you for texting me so much during the game, and it was so cute that you cared to text me back even when you were out with your friends. We’d find any reason to FaceTime, like my Walmart hauls or you showing me the sunset half asleep (and just randomly blurting out wack before passing out again). You still sleep-talk a lot, and it’s adorable. By the end of the summer, I got flirty because I missed you so much, and I knew I’d either have to start dating you or stop talking to you because I was all over you.
          </Text>
          <Text>
            I moved my flight to see you at block party, and it was probably the second-best decision I ever made after asking you on a date. I loved rotting in your bed for two days, I don't know how we did it, We didn’t even go to the bathroom. I was in khakis and dried-out contacts, and we didn’t even eat. But I’d love to spend all that uninterrupted time with you again. That photo you took of me at the Virginian when we finally broke our fast, you can see just how much I loved spending time with you. My eyes and smile are completely fixated on you, as they still are to this day. You still make me nervous when you look at me because I don’t know how I got someone as pretty as you to be my girlfriend for two years. Oh and about asking you on that date—I was soooo nervous. I planned to do it that night, and it was all I could think about. I was sweating, and my legs were shaking in the Standard lobby after walking you back. I’m glad you said yes, and I’m glad I got you to like those Asados wings. That reminds me, Leila saw me sitting in the lobby right before our first date which was cute and she said she would send you down shortly and not to worry about the delay.
          </Text>
          <Text>
            I love learning about all your quirks; they make me love you even more. I’m all ears when it comes to hearing about your day, the good and the bad. I want to know about every second we had to spend apart. I know I’m not great about sharing mine, and you want to know more, but I’m a boring person, unfortunately, and you’re the only person I share anything with. I love walking to Macy’s even if I get to spend just half an hour with you, it makes my day so much better. For how much time we spend there, we sure don’t know much about the place lol. I love holding you. I want you to feel safe. I want you to feel like I will always be there for you, whether to listen to you or protect you from anything that threatens your happiness or keeps a smile from your beautiful face.
          </Text>
          <Text>
            You have the most gorgeous smile, and I could stare into your eyes all day. Every morning I wake up next to you and it makes me fall more in love. You look incredible when the morning rays hit your hair—it makes me want to wake up like this for the rest of my life. I, on the contrary, have been told I look like a wild rooster in the morning. Kiddingggg, I know my hair is crazy when I wake up.
          </Text>
          <Text>
            Thank you for making my birthday so special. I didn’t expect you to throw a surprise party, and it was such a thoughtful gesture. I had an amazing time with everyone, even though I spent a good part of the night besides the toilet. The brewery tour was incredible, thank you for being so thoughtful and making it a birthday I’ll never forget.
          </Text>
          <Text>
            I get excited every time I talk to you and love spending time with you. Our Friday lunch dates and times at the Stumble Inn were always a blast—sad to see them come to an end. I love FaceTiming you, even if it's just for a few minutes, just to see your face and hear your voice. While I can't be there to witness you attempt to reach over and kiss me goodnight no matter how tired you are, hearing you beside me on the phone instantly comforts me. Whenever I have fun, I want nothing more than to have you by my side. If you ever mention something you like, I instantly jot it down because I want everything to be perfect for you. I really can’t wait to go to the champagne bar at The Plaza with you.
          </Text>
          <Text>
            I love using the words “fun” and “romantic” to describe our relationship. We’ve had so many fun times that stemmed from nothing more than just being in each other’s company. I love how your roommates said they used to hear us laugh all night—because I never want you to stop laughing. Your laugh is contagious, and seeing you happy makes me feel like the most fulfilled person on earth. We’ve had so many incredible dates, thoughtful gifts, and amazing memories that have kept our spark alive and well over these past two years. My love for you grows every day, and I’m so attracted to you that I can hardly handle being apart from you or not being able to kiss you for days on end. (Excited for Smooch city this weekend in Virginia!)
          </Text>
          <Text>
            I’m so proud of everything you’ve accomplished this past year. You’ve been working so hard and learning so quickly on the job. Programming is far from easy, yet you’ve done wonders, from all those machine learning research projects to setting up that entire Kaggle challenge. You continue to amaze me every day with how hard you work and how you give your best effort, no matter the task. Every essay of yours I’ve read has been tremendous and so well-worded—you’re such a talented writer. With how smart you are, any school would be lucky to have you.
          </Text>
          <ScratchOff text={text}/>
          <br />
          <br />
          <hr />
          <TextBasic>
            Maybe the next website will be shabbywedsmoeski.com… <br/>
            Miss you and love you more every day, xoxo gorgeous. -Shabby 😘
          </TextBasic>
        </TextSection>
      </Fade>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
    padding: 30px 20px;
    background: #C7FFFB;
`;

const Title = styled.h1`
  font-family: "Dancing Script", cursive;
  font-size: 2.5em;
  font-weight: bold;
  margin: 0 0 20px 0;
`;

const TextSection = styled.div`
  max-width: 50%;
  margin: 0 auto;
`;
const Text = styled.p`
  font-family: "Satisfy", cursive;
  font-size: 16px;
  font-weight: 100;
  color: #666666;
  margin-top: 0px;
  text-align: left;
  text-indent: 20px;
`;

const TextBasic = styled.p`
  font-family: "Satisfy", cursive;
  font-size: 16px;
  font-weight: 100;
  color: #666666;
  margin-top: 0px;
  text-align: left;
`;

export default LetterSection;
