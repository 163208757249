import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

interface Props {
    text: string;
}

function ScratchOff({text}: Props) {
    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const messageRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const message = messageRef.current;

    if (!canvas || !message) return;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    canvas.width = message.offsetWidth;
    canvas.height = message.offsetHeight;

    ctx.fillStyle = '#23F9EB';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const revealText = (e) => {
        const rect = canvas.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
        ctx.globalCompositeOperation = 'destination-out';
        ctx.beginPath();
        ctx.arc(x, y, 20, 0, 2 * Math.PI);
        ctx.fill();
    };

    canvas.addEventListener('mousemove', revealText);

    return () => {
      canvas.removeEventListener('mousemove', revealText);
    };
  }, []);

  return (
    <Container>
      <Message ref={messageRef}>
        <Text>{text}</Text>
      </Message>
      <Canvas ref={canvasRef}></Canvas>
    </Container>
  );
}

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
`;

const Message = styled.div`
  font-size: 24px;
  font-weight: bold;
  color: black;
  padding: 20px;
  background: #C7FFFB;;
`;

const Text = styled.p`
  font-family: "Satisfy", cursive;
  font-size: 16px;
  font-weight: 100;
  color: #666666;
  margin-top: 0px;
  text-align: left;
  text-indent: 20px;
`;

export default ScratchOff;
