import React from 'react';
import styled from 'styled-components';
import img1 from '../images/img5.jpg';
import { Fade } from 'react-awesome-reveal';

function LargeShowcaseSection() {
  return (
    <HeaderContainer>
        <InnerContainer>
            <TextContainer>
                <Fade>
                  <TitleText>My better half</TitleText>
                  <Text>
                    Being with you has given me a whole new understanding of the word "love." I never imagined I would fall for someone as deeply as I have for you. And no matter how much I love you, I find myself falling for you more and more every day. You continue to amaze me, make me laugh, and fill my life with joy. There's nothing about you that I could ever describe as boring or mundane. From your quirks to your chipper personality, being by your side heals any bad day and removes any negative thoughts.
                  </Text>
                  <Text>
                    I want to be there to help you through every challenge. No matter what the issue, from internet connection to being stressed about applications, I want to make everything perfect for you. I strive to be my best because I want to make you proud to be my girlfriend, just as I am and always will be, proud to be your boyfriend.
                  </Text>
                </Fade>
            </TextContainer>
            <Image src={img1} alt="img1" />
        </InnerContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
    padding: 30px 20px 50px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerContainer = styled.div`
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: auto;
    padding-left: 20px;
    border-radius: 10px;
`;

const TextContainer = styled.div`
    max-width: 300px; /* Set a max-width for the text container */
    margin-right: 20px;
    padding-top: 10px;
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  text-align: left;
  text-indent: 20px;
  font-family: "Satisfy", cursive;
  color: #666666;
`;

const TitleText = styled.p`
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 20px 0;
    text-align: left;
    color: #60FDF3;
    font-family: "Dancing Script", cursive;
`;

const Image = styled.img`
    width: auto;
    height: 450px; /* Adjust height as needed */
    border-radius: 10px;
`;

export default LargeShowcaseSection;
