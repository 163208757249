import React from 'react';
import styled from 'styled-components';
import Header from './components/Header.tsx';
import ImageSection from './components/ImageSection.tsx';
import IntroSection from './components/IntroSection.tsx'
import IntroLetter from './components/IntroLetter.tsx';
import ShowcaseSection from './components/ShowcaseSection.tsx'
import LargeShowcaseSection from './components/LargeShowcaseSection.tsx'
import LetterSection from './components/LetterSection.tsx'

function App() {

  const firstSetImages = [
    { src: require('./images/imgv1.jpg'), caption: 'Jennis Wedding' },
    { src: require('./images/imgv2.jpg'), caption: 'Catch of the Day' },
    { src: require('./images/imgv11.jpg'), caption: 'Going out aloneeeee in the rain' },
    { src: require('./images/imgv4.jpg'), caption: 'Our first date function 🥹' }
  ];

  const secondSetImages = [
    { src: require('./images/imgv9.jpg'), caption: 'Goated Margs' },
    { src: require('./images/imgv6.jpg'), caption: 'Graduation 🎓' },
    { src: require('./images/imgv7.jpg'), caption: 'One of us was very drunk here 👀' },
    { src: require('./images/imgv8.jpg'), caption: 'Sissy said I looked good' }
  ];


  return (
    <Container>
      <Header />
      <IntroSection />
      <IntroLetter />
      <ImageSection images={firstSetImages}/>
      <ShowcaseSection />
      <ImageSection images={secondSetImages}/>
      <LargeShowcaseSection />
      <LetterSection />
    </Container>
  );
}

const Container = styled.div`
  text-align: center;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #fafafa;
`;

export default App;
