import React from 'react';
import styled from 'styled-components';
import { Fade } from "react-awesome-reveal";
import img1 from '../images/imgv3.jpg';
import img2 from '../images/imgv5.jpg';
import img3 from '../images/imgv10.jpg';

function ShowcaseSection() {
  return (
    <HeaderContainer>
        <InnerContainer>
            <PhotoContainer>
                <ImageCluster>
                    <ClusterImage src={img1} alt="Image 1" />
                    <ClusterImage src={img2} alt="Image 2" />
                    <ClusterImage src={img3} alt="Image 3" />
                </ImageCluster>
            </PhotoContainer>
            <TextContainer>
                <Fade>
                    <TitleText>My first time laying eyes on you</TitleText>
                    <LineBreak />
                    <Text>
                        You always ask about our first interaction—well, besides the awkward “hi’s” once before. It was at that pregame at Crow’s apartment before our first official date. Crow was a real character, but then again, who in our frat wasn’t a bit quirky. Lol I will never get over the fact that “quirky” was the word Brian chose as his first adjective for Jen.
                    </Text>
                    <Text>
                        I was very anxious waiting for you, not knowing what to expect since we’d only exchanged a few words before. I wasn’t even sure if you’d show up. And now look at me, burning through my weekly allocation of words in just a day texting youuuu.
                    </Text>
                    <Text>
                        When you walked through the door, you looked absolutely stunning. My eyes were glued to you because honestly I was so nervous. You looked so pretty, I could have spent all day just admiring you. I think it's so cute how you’d always check with your sister about your outfit before our dates, wanting everything to be perfect, I always wanted to look my best for you. There's a meme that has a picture of a guy stressed with a caption that goes: when a girl wants to hang out a 4th time this week and you have already worn your top 3 outfits, I couldn't have related to something more back in the day. 
                    </Text>
                    <Text>
                        I had to muster up the courage to talk to you because I didn’t want to be the weirdo who didn’t introduce himself to his own date. I offered you a drink and called you the infamous “amazing” because, well, that’s exactly what you were. You looked flattering and I didn’t want to cross any boundaries or come off as too forward, so “amazing” felt safe. (Used it a couple more times for the following date functions as well) I took a while to come up with it, but it still fits you perfectly. And now, I know you’re not just amazing for your looks—you’re amazing for the person you are.
                    </Text>
                    <LineBreak />
                </Fade>
            </TextContainer>
        </InnerContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.div`
    padding: 30px 20px;
    background: #f2f2f2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const InnerContainer = styled.div`
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const PhotoContainer = styled.div``;

const TextContainer = styled.div`
    width: 50%;
`;

const ImageCluster = styled.div`
  display: flex;
  position: relative;
  width: 50%; /* Adjust width if necessary */
`;

const ClusterImage = styled.img`
  width: 200px; /* Smaller image width */
  height: auto;
  object-fit: cover;
  border-radius: 10px;
  position: absolute;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  
  &:nth-child(1) {
    left: -50px;
    top: -150px;
    z-index: 2;
  }
  
  &:nth-child(2) {
    left: 100px; /* Adjust for overlap */
    top: -50px;
    z-index: 3;
  }
  
  &:nth-child(3) {
    left: 200px; /* Adjust for overlap */
    top: -200px;
    z-index: 4;
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-weight: 300;
  margin-top: 0px;
  text-align: left;
  text-indent: 20px;
  font-family: "Satisfy", cursive;
  color: #666666;
`;

const TitleText = styled.p`
    font-size: 32px;
    font-weight: 700;
    margin: 0 0 20px 0;
    text-align: left;
    color: #60FDF3;
    font-family: "Dancing Script", cursive;
`;

const LineBreak = styled.hr``;

export default ShowcaseSection;
