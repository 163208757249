import React from 'react';
import styled from 'styled-components';

function IntroSection() {
    return (
        <HeaderContainer>
            <Title>a confession?</Title>
            <Subtitle>Fun fact of the day: Building an entire website was quicker than stringing together a few paragraphs...</Subtitle>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    padding: 30px 20px;
    background: #C7FFFB;
`;

const Title = styled.h1`
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 10px;
`;

const Subtitle = styled.p`
    font-size: 18px;
    font-weight: 300;
    margin-top: 0px;
    color: #666666;
`;

export default IntroSection;
