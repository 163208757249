import React from 'react';
import styled from 'styled-components';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Typewriter from 'typewriter-effect';

const images = Array.from({ length: 13 }, (_, i) => require(`../images/img${i + 1}.jpg`));

function Header() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 600,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        fade: true,
        arrows: false
    };

    return (
        <HeaderContainer>
            <SliderContainer {...settings}>
                {images.map((image, index) => (
                    <>
                        <Slide><Image key={index} src={image} alt={`img${index + 1}`} /></Slide>
                    </>
                ))}
            </SliderContainer>
            <Overlay>
            <Title>
                <Typewriter
                    onInit={(typewriter) => {
                    typewriter
                        .typeString("I Love You")
                        .pauseFor(5000)
                        .deleteChars(10)
                        .pauseFor(1000)
                        .start();
                    }}
                    options={{
                        loop: true,
                        delay: 150,
                        deleteSpeed: 100,
                    }}
                />
            </Title>
                <Subtitle>Happy 2 Year Anniversary Moeski</Subtitle>
            </Overlay>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;
`;

const SliderContainer = styled(Slider)`
    height: 100%;
    .slick-slide {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .slick-dots {
        bottom: 20px;
        z-index: 10;
    }
    .slick-dots li button:before {
        color: white;
    }
    .slick-dots .slick-active button:before {
        color: white;
    }
`;

const Slide = styled.div`
    height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

const Image = styled.img`
    height: 100%;
    width: 100vw;
    object-fit: cover;
    object-position: center;
`;

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: white;
    padding: 20px;
`;

const Title = styled.h1`
    font-family: "Cedarville Cursive", cursive;
    font-style: normal;
    font-size: 6vw;
    font-weight: bold;
    margin-bottom: 10px;
    color: #B0FDF8;
`;

const Subtitle = styled.p`
    font-size: 1.5vw;
    font-weight: 300;
    margin-top: 0;
    font-style: italic;
`;

export default Header;
