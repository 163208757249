import React from 'react';
import { Fade } from 'react-awesome-reveal';
import styled from 'styled-components';

function IntroLetter() {
    return (
        <HeaderContainer>
            <TextContainer>
                <Fade>
                    <Text> 
                        Sooooo here we are, attempting something that may or may not end up becoming an actual love letter—or just a chaotic mess of words because, well, you once called me emotionally closed off, and I can't have you thinking I am a terrible boyfriend. Sure, I'm not the best at expressing emotions. I like to keep them bottled up, you know, because letting people in is scary, yada yada yada. But here I am, diving into this, let me rephrase, "confession of love" (not to be mistaken for a love letter) because I love you and you truly are the only person who has gotten me to open up to this day.<br /><strong>You are my best friend.</strong>
                    </Text>
                    <Text>
                        We're just dancing around the point here, so I might as well dive in. And would you look at that, I've managed to write a whole lot of nothing… If only hitting the word count in English class was this easy. Now imagine if this was all just a ruse—me rambling on and never actually getting to the letter. That would be brutal, right? You'd be so mad, the betrayal, the shock... ahhh! (How many more can I throw in there)
                    </Text>
                    <Text>
                        I'm losing it and I blame you, you're the wack one in this relationship and it's wearing off on me. Anyway, gotta get the insults and jokes out of the way so I can dig deep and find those romantic feelings buried at the bottom of my heart. Because unlike you, I actually have one… boommmm, roasted. Just kidding—they're in there, don't worry. That was the last of it… maybe.
                    </Text>
                    <Text>
                        Might need to release a "redacted" version of this because this is the director’s cut, the behind-the-scenes of my brain as I write. Look at that, you wanted a paragraph, and here it is. My work here is done. Toodles. And you say I don’t talk enough... yet I just cranked out this entire prologue in minutes. The letter itself? Piece of cake, because all these feelings I’ve been keeping bottled up were just waiting for the perfect medium to be expressed to you through (duh); and here they are in the form of this letter. So, what do you think,  convincing enough? I bet you’re either smiling or pouting (Cute either way, I loveee youuuu). Honestly, I can’t tell how this is going to land. But I’ll assume you’re smiling because, let’s face it we both know I’m hilarious!!!
                    </Text>
                    <Text>
                        But in all seriousness, no matter how wack this sounds, I genuinely mean it. I love you so much, even if I downplay it with jokes to avoid being too mushy or sappy. My feelings for you are real, and I might suck at putting them into words, but I hope my actions show you how much you mean to me. If this letter does not do a good job at bringing my love for you to light I am sorry, it wasn't meant to be cliche, I really am appreciative of you and your love. You’re my everything and I can’t believe I survived all these years without you. I can’t wait to spend the rest of my life with you, making more memories, laughing at my terrible jokes, and maybe, just maybe, writing you a proper love letter someday.
                    </Text>
                </Fade>
            </TextContainer>
        </HeaderContainer>
    );
}

const HeaderContainer = styled.div`
    padding: 40px 20px 30px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Text = styled.p`
    font-family: "Satisfy", cursive;
    color: #666666;
    font-size: 14px;
    font-weight: 300;
    margin-top: 0px;
    text-align: left;
    text-indent: 40px;
    strong {
        font-style: italic;
        font-weight: 400;
        color: #000000;
    }
`;

const TextContainer = styled.div`
    max-width: 60%;
`;

export default IntroLetter;
